import React from 'react'

export interface NewsCard {
    link: string,
    title: string,
    description: string,
    image: string,
}

const NewsCard = ({ link, title, description, image }: NewsCard) => {

  return (
    <a 
      href={link}
      className="flex flex-col md:flex-row items-center gap-4 md:gap-8 AnimatedUp p-8 rounded-3xl bg-gray-100 text-mg-purple w-full max-w-sm md:max-w-4xl no-underline"
      target="_blank"
    >
        <div className="aspect-square flex w-full h-full md:w-48 md:h-48">
          <img src={image} alt="" className="w-full h-full rounded-xl" />
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <h3 className="text-lg mb-1">{title}</h3>
          </div>
          <div className="flex flex-col gap-4">
              <p className="text-base mb-0">{description}</p>
              <p className="text-base mb-0 font-semibold underline hover:no-underline">Read more</p>
          </div>
        </div>

    </a>
  )
}

export default NewsCard